<template>
  <v-app id="inspire">
    <v-snackbar
      v-model="$store.state.snackbar"
      :timeout="$store.state.timeoutSnackbar"
      top
    >
      {{ message }} <v-icon color="green">mdi-check-bold</v-icon>
    </v-snackbar>

    <v-snackbar
      v-model="$store.state.alert1"
      :timeout="$store.state.alert1Timeout"
      top
    >
      {{ $store.state.alert1Message }}
      <v-icon :color="$store.state.alert1Color">{{
        $store.state.alert1Icon
      }}</v-icon>
    </v-snackbar>

    <v-app id="inspire">
      <sidebar />
      <navbar />
      <v-main>
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
        <!-- <v-container class="fill-height" fluid>
          <router-view />
        </v-container> -->
      </v-main>
    </v-app>
  </v-app>
</template>

<script>
import navbar from "@/components/navbar/navbar.vue";
import sidebar from "@/components/sidebar/sidebar.vue";
import { getUser, getUsers } from "@/services/users";
import { getRoutes } from "../../services/routes";
export default {
  components: { navbar, sidebar },
  computed: {
    message() {
      return this.$store.state.snackbarMessage || "Salvo com sucesso";
    },
    drawer() {
      return this.$store.state.drawer;
    },
  },
  beforeCreate() {
    if (!this.$store.state.user.email) {
      getUser()
        .then((user) => {
          this.$store.state.user = user;
          this.$store.state.userJson = JSON.stringify(user);
          this.$store.state.user.descriptions = user.routes.map(
            (route) => route.description
          );
          this.$store.state.news.color = user.color;
          this.$store.state.news.logo = user.logo;
        })
        .catch(() => this.$router.push("/login"));
      getUsers().then((data) => {
        return (this.$store.state.users = data.map((item) => ({
          ...item,
          totalRoutes: item.routes.length,
        })));
      });
    }
    getRoutes().then((data) => (this.$store.state.routes = data));
  },
  watch: {
    "$store.state.snackbar"(val) {
      if (!val) this.$store.state.snackbarMessage = null;
    },
  },
};
</script>
