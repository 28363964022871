<template>
  <v-app-bar
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    app
    color="blue darken-3"
    dark
    dense
  >
    <v-btn
      class="mx-2"
      fab
      icon
      x-small
      @click.stop="$store.commit('toggleDrawer')"
    >
      <v-icon dark> mdi-menu </v-icon>
    </v-btn>
    <v-spacer></v-spacer>

<v-text-field
  v-if="$store.state.searched"
            v-model="$store.state.search"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
<v-spacer></v-spacer>
    <v-menu open-on-hover bottom offset-y>
      <template v-slot:activator="{ on, attrs }">
        <span color="primary" dark v-bind="attrs" v-on="on">
          <v-icon>mdi-account</v-icon>
        </span>
      </template>

      <v-list flat>
        <v-subheader>{{$store.state.user.email}}</v-subheader>
        <v-list-item-group v-model="model" color="blue">
          <v-list-item :to="item.path" v-for="(item, i) in userMenus" :key="i">
            <v-list-item-icon>
              <v-icon :color="item.color" dense>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title :style="{ color: item.color }">{{
                item.name
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
export default {
  data: () => ({
    model: 1,
    userMenus: [
      { icon: "mdi-pencil-outline", name: "Editar", color: "", path: '/profiles' },
      { icon: "mdi-power", name: "Sair", color: "red", path: '/login' },
    ],
  })
};
</script>

<style lang="scss" scoped>
</style>