import axios from 'axios'
import baseUrl from './baseUrl'

const errors = err => {
  console.error(err.response ? err.response.data.stack : err.stack)
  return Promise.reject(err.response ? err.response.data.message:'Houve uma falha na comunição, verifique sua internet e tente novamente')
}
const headers = {token: localStorage.getItem('token')}
const routes = axios.create({ baseURL: baseUrl.tnAdmin, headers })

export const getRoutes = () => routes.get('/routes').then(({data}) => {
  return data.sort((a, b) => (a.description < b.description)?-1:1)
})
.catch(errors)