<template>
  <v-navigation-drawer
    v-model="$store.state.drawer"
    :clipped="$vuetify.breakpoint.lgAndUp"
    app
  >
    <v-list dense>
      <template v-for="item in computedItems">
        <v-row v-if="item.heading" :key="item.heading" align="center">
          <v-col cols="6">
            <v-subheader v-if="item.heading">
              {{ item.heading }}
            </v-subheader>
          </v-col>
          <v-col cols="6" class="text-center">
            <a href="#!" class="body-2 black--text">EDIT</a>
          </v-col>
        </v-row>
        <v-list-group
          v-else-if="item.children"
          :key="item.text"
          v-model="item.model"
          :prepend-icon="item.model ? item.icon : item['icon-alt']"
          append-icon=""
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-for="(child, i) in item.children" :key="i" link>
            <v-list-item-action v-if="child.icon">
              <v-icon>{{ child.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ child.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item :to="item.to" v-else :key="item.text" link>
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
        <v-list-item @click="openDocs" v-if="isAdmin">
          <v-list-item-action>
            <v-icon>mdi-file-document-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Documentação
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import baseUrl from "../../services/baseUrl";
export default {
//   
// 
// 
// profiles
// schedules
// users
  data: () => ({
    source: "",
    dialog: false,
    model: 1,
    items: [
      { icon: "mdi-monitor-dashboard", text: "Dashboards", to: '/dashboards', roles: ['Parceiro', 'Anunciante', 'Administrador', 'Aprovador'] },
      { icon: "mdi-calendar-arrow-right", text: "Agenda", to: '/schedule', roles: ['Administrador', 'Aprovador'] },
      { icon: "mdi-newspaper-plus", text: "Notícias", to: '/news', roles: ['Parceiro', 'Anunciante', 'Administrador', 'Aprovador'] },
      { icon: "mdi-bullhorn-outline", text: "Anúncios", to: '/advertisements', roles: ['Anunciante', 'Administrador', 'Aprovador'] },
      { icon: "mdi-play-box-multiple-outline", text: "Playlists", to: '/playlists', roles: ['Anunciante', 'Administrador', 'Aprovador'] },
      { icon: "mdi-cellphone-message", text: "Totens", to: '/totens', roles: ['Administrador'] },
      { icon: "mdi-account-star-outline", text: "Usuários", to: '/users', roles: [ 'Administrador'] },
      // { icon: "mdi-security", text: "Controle de acessos", to: '/access-control' },
      //security
      //cellphone-message
      // {
      //   icon: "mdi-chevron-up",
      //   "icon-alt": "mdi-chevron-down",
      //   text: "Labels",
      //   model: true,
      //   children: [{ icon: "mdi-plus", text: "Create label" }],
      // },
      // {
      //   icon: "mdi-chevron-up",
      //   "icon-alt": "mdi-chevron-down",
      //   text: "More",
      //   model: false,
      //   children: [
      //     { text: "Import" },
      //     { text: "Export" },
      //     { text: "Print" },
      //     { text: "Undo changes" },
      //     { text: "Other contacts" },
      //   ],
      // },
      // { icon: "mdi-cog", text: "Settings" },
      // { icon: "mdi-message", text: "Send feedback" },
      // { icon: "mdi-help-circle", text: "Help" },
      // { icon: "mdi-cellphone-link", text: "App downloads" },
      // { icon: "mdi-keyboard", text: "Go to the old version" },
    ],
  }),
  methods:{
    openDocs(){
      const token = localStorage.getItem('token')
      if(token && token.split('.').length > 0 && this.isAdmin)
      window.open(`${baseUrl.tnAdmin}/api-docs?token=${token.split('.')[1]}`,'_blank')
    }
  },
  computed: {
    computedItems(){
      return this.items.filter(item => item.roles.includes(this.$store.state.user.type))
    },
    isAdmin(){
      return this.$store.state.user.type == 'Administrador'
    }
  }
};
</script>

<style lang="scss" scoped>
</style>